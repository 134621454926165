footer {
  height: 360px;
  background: #1f2021;
  background-image: url("../img/footer-img.png");
  background-size: 50% auto;
  background-position: left;
  background-repeat: repeat-x;
}

.footer-component {
  padding: 30px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-content p {
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 26.04px;
  text-align: left;
  color: #ffffff;
  width: 500px;
}

.footer-content-2 {
  width: 370px;
}

.footer-content-2 span {
  font-family: "DM Sans", sans-serif;
  font-size: 26px;
  font-weight: 500;
  line-height: 26.04px;
  color: #f76033;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 20px;
}

.footer-content-2 ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.footer-content-2 li {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 26.04px;
  color: #ffffff;
  width: calc(50% - 30px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding-left: 20px;
}

.footer-content-2 li::before {
  content: "•";
  color: #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 24px;
}

.footer-content-3 {
  width: 320px;
}

.footer-content-3 span {
  font-family: "DM Sans", sans-serif;
  font-size: 26px;
  font-weight: 500;
  line-height: 26.04px;
  color: #f76033;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 20px;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.footer-contact-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.footer-contact-content p {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 26.04px;
  color: #ffffff;
}

.footer-contact-content a {
  text-decoration: none;
}

.footer-contact-content p:hover {
  color: #f76033;
}

.footer-copyright {
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: 400;
  line-height: 26.04px;
  text-align: center;
  color: #ffffff;
}

@media (max-width: 1024px) {
  footer {
    height: auto;
    padding: 20px;
    background-image: none;
  }
  .footer-component {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
  }

  .footer-content,
  .footer-content-2,
  .footer-content-3 {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }

  .footer-content-2 span,
  .footer-content-3 span {
    justify-content: flex-start;
  }

  .footer-contact {
    align-items: flex-start;
    width: 100%;
  }

  .footer-contact-content {
    gap: 10px;
  }

  .footer-content-2 ul {
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
  }

  .footer-content-2 li {
    width: calc(50% - 10%);
    padding-left: 0;
  }

  .footer-content p {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .footer-component {
    padding: 15px 20px;
  }

  .footer-content p,
  .footer-content-2,
  .footer-content-3 {
    width: 100%;
    text-align: left;
    padding: 0;
  }

  .footer-content-2 ul {
    gap: 10px;
  }

  .footer-content-2 li {
    font-size: 16px;
    width: calc(100% - 20px);
    padding-left: 20px;
  }

  .footer-contact-content p {
    font-size: 16px;
  }

  .footer-copyright {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .footer-component {
    padding: 10px 15px;
  }

  .footer-content p,
  .footer-content-2,
  .footer-content-3 {
    font-size: 16px;
    width: 100%;
  }

  .footer-content-2 ul {
    gap: 8px;
  }

  .footer-contact-content p {
    font-size: 16px;
  }

  .footer-copyright {
    font-size: 16px;
  }
}
