.why-choose-us {
  margin: 100px 5%;
}

.why-choose-us p {
  margin: 1%;
  font-family: Merienda One;
  font-size: 4vw;
  font-weight: 400;
  line-height: 54.87px;
  text-align: center;
}

.second-p p{
    padding-top: 20px;
  font-size: 40px;
  color: #f76033;
}

.why-component {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 20px;
}

.why-content {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 6px;
  border-style: solid;
  border-image-source: linear-gradient(
    137.04deg,
    #f09229 -1.45%,
    #a9383e 99.1%
  );
  border-image-slice: 1;
  gap: 25px;
  width: 300px;
  height: 300px;
  padding: 10px;
}

.why-content-img {
  height: 80px;
}

.why-content img {
  width: 78px;
  height: 78px;
}

.why-content p {
  font-family: "DM Sans", sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 41.66px;
  text-align: center;
  color: black;
  min-height: 30px;
}

.why-content p:last-child {
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  min-height: 100px;
}

@media (max-width: 767px) {
  .why-choose-us {
    margin: 60px 3%;
  }

  .why-choose-us p {
    font-size: 6vw;
    line-height: 8vw;
  }

  .why-component {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .why-content {
    margin-top: 20px;
    width: 100%;
    max-width: 280px;
    height: auto;
    padding: 15px;
    gap: 15px;
  }

  .why-content-img {
    height: 60px;
  }

  .why-content img {
    width: 60px;
    height: 60px;
  }

  .why-content p {
    font-size: 6vw;
    line-height: 7vw;
  }

  .why-content p:last-child {
    font-size: 4.5vw;
    line-height: 6vw;
    min-height: auto;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .why-choose-us {
    margin: 25px 4%;
  }

  .why-choose-us p {
    font-size: 5vw;
    line-height: 7vw;
  }

  .why-component {
    gap: 15px;
    flex-wrap: wrap;
  }

  .why-content {
    width: 100%;
    max-width: 260px;
    height: auto;
    padding: 15px;
  }

  .why-content-img {
    height: 70px;
  }

  .why-content img {
    width: 70px;
    height: 70px;
  }

  .why-content p {
    font-size: 4.5vw;
    line-height: 6vw;
  }

  .why-content p:last-child {
    font-size: 3.5vw;
    line-height: 5vw;
    min-height: auto;
  }
}

@media (max-width: 1250px) {
  .why-content {
    height: auto;
  }
}
