.contacts {
  margin: 5%;
  position: relative;
}

.contact-place {
  position: absolute;
  top: 0%;
  right: 0;
}

.contact-place img {
  width: 80%;
}

.contacts p {
  margin: 0%;
  font-family: "Merienda One", sans-serif;
  font-size: 3vw;
  font-weight: 400;
  line-height: 54.87px;
  text-align: left;
}

.contact-buttons {
  margin: 50px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 70%;
}

.contact-buttons a {
  text-decoration: none;
}

.contact-buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  border-radius: 16px 16px 0px 16px;
  /* opacity: 0px; */
  background: #f76033;
  border: 2px solid #f76033;
  font-family: "DM Sans", sans-serif;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: 26.04px;
  text-align: center;
  gap: 10px;
  cursor: pointer;
  padding: 0 10px;
}

.contact-buttons .mail-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  border-radius: 16px 16px 16px 0px;
  /* opacity: 0px; */
  background: #f76033;
  border: 2px solid #f76033;
  font-family: "DM Sans", sans-serif;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: 26.04px;
  text-align: center;
  gap: 10px;
  cursor: pointer;
  padding: 0 10px;
}

.contact-buttons button:hover {
  background-color: #fff;
  color: black;
}

.contact-buttons button img {
  width: 42px;
  height: 42px;
}

.contact-note p {
  color: #d40101;
}

.contact-note-content {
  margin: 15px 0px 0 60px;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
}

.contact-note-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.contact-note-content li {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .contacts {
    margin: 10px 5%;
  }

  .contacts p {
    font-size: 28px;
    line-height: 40px;
  }

  .contact-place img {
    width: 100%;
    max-width: 300px;
  }

  .contact-buttons {
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .contact-buttons button {
    width: 100%;
    max-width: 300px;
  }

  .contact-buttons .mail-button{
    width: 100%;
    max-width: 400px;
  }

  .contact-buttons button img {
    width: 36px;
    height: 36px;
  }

  .contact-note-content {
    margin-left: 20px;
    font-size: 18px;
  }

  .contact-note-content ul {
    padding-left: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .contacts {
    margin: 5% 3%;
  }

  .contacts p {
    font-size: 32px;
    line-height: 46px;
  }

  .contact-place img {
    width: 80%;
  }

  .contact-buttons {
    width: 80%;
  }

  .contact-buttons button {
    font-size: 18px;
    height: 45px;
  }

  .contact-buttons button img {
    width: 40px;
    height: 40px;
  }

  .contact-note-content {
    margin-left: 40px;
    font-size: 28px;
  }
}

@media (max-width: 1185px) {
  .contact-place {
    position: static;
    text-align: center;
    margin-top: 20px;
  }
  .contact-place img {
    width: 50%;
  }
  .contact-buttons {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
