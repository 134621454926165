.about-us {
  margin: 170px 5% 0 5%;
}

.about-us p {
  margin: 0;
  font-family: Merienda One, sans-serif;
  font-size: 4vw;
  font-weight: 400;
}

.about-logo {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-logo img {
  width: 50%;
  max-width: 246px;
  height: auto;
}

.about-component {
  margin-top: 80px;
  margin-bottom: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.about-content {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
}

.about-content-1 img {
  max-width: 240px;
  max-height: 472px;
  border-radius: 10px;
}

.about-content-1 {
  position: relative;
}

.about-since {
  position: absolute;
  top: -20px;
  right: -15px;
}

.since-circle {
  background: #f76033;
  border: 8px solid #e9b472;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.since-circle p {
  font-family: "DM Sans", sans-serif;
  color: white;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  margin: 0;
}

.about-description p {
  font-size: 44px;
  font-weight: 500;
  text-align: left;
  margin: 0 40px 20px;
}

.about-content-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.about-content-2 img {
  width: 100%;
  max-width: 240px;
  height: auto;
  border-radius: 10px;
}

.about-component-2 p {
  padding: 10px 0;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-weight: 500;
  text-align: justify;
}

@media (max-width: 1300px) {
  .about-component {
    flex-direction: column;
    padding: 3%;
  }
  .about-content {
    flex-direction: row;
    gap: 20px;
  }
  .about-description p {
    text-align: center;
  }
  .about-content-2 {
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .about-us {
    margin:10% 4% 0 4%;
  }

  .about-us p{
    font-size: 5vw;
  }

  .about-logo img {
    width: 60%;
  }

  .about-content-1 img {
    max-width: 100%;
    max-height: auto;
  }

  .about-description p {
    text-align: center;
    font-size: 4vw;
    margin: 0 20px 20px;
  }

  .about-content-2 {
    flex-wrap: nowrap;
    gap: 20px;
  }

  .about-content-2 img {
    max-width: 50%;
  }

  .about-component{
    padding-bottom: 0;
  }

  .about-component-2{
    padding: 0 3%;
  }

  .about-component-2 p {
    font-size: 3vw;
  }
  .since-circle p{
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .about-us {
    margin: 0 2%;
    padding-top: 20px;
  }

  .about-us p {
    font-size: 6vw;
  }

  .about-logo {
    margin-top: 20px;
  }

  .about-logo img {
    width: 80%;
  }

  .since-circle p {
    font-size: 4vw;
  }

  .about-component {
    padding: 2%;
    margin-bottom: 20px;
  }

  .about-content {
    flex-direction: column-reverse;
    gap: 30px;
  }

  .about-content-1 img {
    max-width: 100%;
    max-height: auto;
  }

  .about-description p {
    text-align: center;
    font-size: 6vw;
    margin: 0 10px 20px;
  }

  .about-content-2 {
    flex-direction: column;
    gap: 10px;
  }

  .about-content-2 img {
    max-width: 60%;
  }

  .about-component-2 p {
    padding: 10px 20px;
    font-size: 5vw;
  }
}
