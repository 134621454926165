.header {
  margin-top: 30px;
}

.header-component {
  padding: 0 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-content-1 {
  font-family: "Merienda One",sans-serif;
  font-size: 4vw;
  font-weight: 400;
  width: 70%;
}

.header-content-1 span {
  color: #ea592d;
}

.header-content-2 {
  margin-top: 10px;
  width: 90%;
  font-size: 24px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}

.header-button {
    display: flex;
  margin-top: 15px;
}

.header-button button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 166px;
  height: 48px;
  border-radius: 0 24px 24px 24px;
  border: none;
  background-color: #ea592d;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  padding: 8px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease;
  overflow: hidden;
}

.header-button img {
  filter: brightness(0) invert(1);
}

.header-button button:hover {
  border: 2px solid #ea592d;
  background-color: #ffffff;
  color: #000000;
}

.header-button button:hover img {
  filter: none;
}

@media (max-width: 1200px) {
  .header-component {
    padding: 0 20px;
  }

  .header-content-1 {
    font-size: 5vw;
    width: 100%;
  }

  .header-content-2 {
    font-size: 20px;
    width: 100%;
  }

  .header-button button {
    width: fit-content;
    gap: 10px;
    height: 40px;
    font-size: 18px;
  }

  .header-button img {
    width: fit-content;
    height: auto;
  }
}

@media (max-width: 768px) {
  .header {
    margin-top: 20px;
  }

  .header-component {
    padding: 0 15px;
    flex-direction: column-reverse;
    align-items: center;
  }

  .header-content-1 {
    font-size: 7vw;
    width: 100%;
    text-align: center;
  }

  .header-content-2 {
    font-size: 6vw;
    width: 100%;
    text-align: center;
  }

  .header-button{
    justify-content: center;
  }

  .header-button button {
    padding: 25px;
    width: fit-content;
    gap: 8px;
    height: 36px;
    font-size: 20px;
  }

  .header-sub-component-2 img {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .header {
    margin-top: 15px;
  }

  .header-content-1 {
    font-size: 7vw;
    text-align: center;
    width: 100%;
  }

  .header-content-2 {
    font-size: 4.5vw;
    text-align: center;
    width: 100%;
  }

  .header-button button {
    gap: 5px;
    height: 35px;
    font-size: 14px;
  }

  .header-button img {
    width: auto;
    height: auto;
  }
}
