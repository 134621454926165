.navbar {
  width: 100%;
  height: 86px;
  background-color: #ffffff;
}

.navbar-component {
  padding: 0 52px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo {
  padding-top: 8px;
}

.navbar-button button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 140px;
  height: 40px;
  border-radius: 0 24px 24px 24px;
  border: 2px solid #ea592d;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 8px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease;
  overflow: hidden;
}

.navbar-button button:hover {
  background-color: #ea592d;
  color: #ffffff;
}

.navbar-button button img {
  width: 16px;
  height: auto;
  transition: transform 0.3s ease;
}

.navbar-button button:hover img {
  filter: brightness(0) invert(1);
}

/* For devices with a max width of 1200px (tablets, smaller laptops) */
@media (max-width: 1200px) {
  .navbar-component {
    padding: 0 32px;
  }

  .navbar-button button {
    width: 120px;
    height: 36px;
    font-size: 12px;
  }

  .navbar-button button img {
    width: 14px;
  }
}


@media (max-width: 768px) {
  .navbar {
    height: 80px;
  }

  .navbar-component {
    padding: 0 16px;
    align-items: flex-start;
  }

  .logo {
    padding-top: 4px;
  }

  .navbar-button button {
    width: 100px;
    height: 32px;
    font-size: 10px;
    padding: 6px;
  }

  .navbar-button button img {
    width: 12px;
  }
}

@media (max-width: 480px) {
  .navbar-component {
    padding: 0 8px;
    align-items: center;
  }

  .logo {
    padding-top: 0;
  }

  .navbar-button button {
    width: 90px;
    height: 28px;
    font-size: 8px;
    padding: 4px;
  }

  .navbar-button button img {
    width: 10px;
  }
}
