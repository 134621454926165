html {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.services {
  margin: 20px 0;
  height: 200px;
}

.service-container {
  width: 100%;
  background: #f76033;
  padding: 5px 0;
  position: relative;
  transform: rotate(-3deg);
  transform-origin: top right;
  overflow: hidden;
}

.services-wrapper {
  display: flex;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.services-list {
  display: flex;
  white-space: nowrap;
  width: 100%;
  animation: scroll 20s linear infinite;
}

.service-item {
  display: inline-block;
  margin: 0 15px;
  font-size: 20px;
  font-weight: 600;
  color: white;
  padding: 8px 12px;
  border-radius: 25px;
  background: none;
}

.hero_mouse-scroll {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.arrow-container {
  position: relative;
  text-align: center;
}

.down-arrow {
  width: 10px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(45deg);
  animation: moveDown 2s ease-in-out infinite;
}

.mouse-image {
  width: 70%;
  position: relative;
}

@keyframes moveDown {
  0% {
    transform: translateY(60px) rotate(45deg);
  }
  50% {
    transform: translateY(100px) rotate(45deg);
  }
  100% {
    transform: translateY(60px) rotate(45deg);
  }
}

@media (max-width: 1200px) {
  .service-item {
    font-size: 18px;
    margin: 0 20px;
    padding: 9px 14px;
    border-radius: 30px;
  }
}

@media (max-width: 768px) {
  .service-container {
    padding: 10px 0;
    transform: rotate(0deg);
  }

  .service-item {
    font-size: 16px;
    margin: 0 10px;
    padding: 7px 10px;
    border-radius: 20px;
  }
  .hero_mouse-scroll {
    margin-top: 20px;
  }
  .mouse-image {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .services {
    height: fit-content;
  }
  .service-item {
    font-size: 14px;
    margin: 0 8px;
    padding: 6px 8px;
    border-radius: 15px;
  }
  .hero_mouse-scroll {
    margin-top: 10px;
  }
  .mouse-image {
    width: 70%;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
