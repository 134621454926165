.our-recipe {
  margin: 100px 5%;
}

.our-recipe p {
  margin: 0;
  font-family: Merienda One, sans-serif;
  font-size: 4vw;
  font-weight: 400;
}

.recipe-component {
  margin: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.recipe-content {
  width: 100%;
  max-width: 1000px;
  height: fit-content;
  min-height: 500px;
  border-radius: 0 100px 32px 32px;
  background-color: #fcfbfb;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.recipe-content img {
  width: 100%;
  max-width: 1000px;
}

.quality-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 0 20px;
}

.quality-content label {
  font-family: Merienda One, sans-serif;
  font-size: 30px;
  font-weight: 400;
}

.quality-content span {
  font-family: Merienda One, sans-serif;
  font-size: 30px;
  font-weight: 400;
}

.quality-content p {
  margin: 0%;
  margin-left: 10px;
  font-family: "DM Sans", sans-serif;
  font-size: 30px;
  font-weight: 400;
}

.quality-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 70px;
  gap: 30px;
}

.quality-price button {
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  width: 191px;
  height: 56px;
  border-radius: 16px 16px 16px 0;
  background: #f76033;
  border: 2px solid #f76033;
  cursor: pointer;
}

.quality-price button:hover {
  background-color: #ffffff;
  color: black;
}

.quality-price-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.quality-price-content img {
  width: 10%;
  height: 10%;
}

.quality-price-content p {
  margin: 0%;
  font-family: "DM Sans", sans-serif;
  font-size: 30px;
  font-weight: 400;
  width: 100%;
  max-width: 400px;
}

.recipe-quality {
  margin: 0 5%;
  position: absolute;
  top: -50px;
  right: 0;
  width: 130px;
}

@media (max-width: 767px) {
  .our-recipe {
    margin: 20px 3%;
  }

  .our-recipe p {
    font-size: 6vw;
  }

  .recipe-component {
    margin: 20px 0;
    flex-direction: column;
    padding: 0 5%;
  }

  .recipe-content {
    gap: 20px;
    min-height: auto;
  }

  .recipe-content img {
    width: 100%;
    max-width: 100%;
  }

  .quality-content label,
  .quality-content span,
  .quality-content p,
  .quality-price-content p {
    font-size: 4.3vw;
  }

  .quality-price {
    margin-left: 0;
    gap: 20px;
    flex-direction: column;
  }

  .quality-price button {
    width: 100%;
    max-width: 250px;
    font-size: 20px;
  }

  .quality-price-content img {
    width: 15%;
    height: auto;
  }

  .recipe-quality {
    margin: 0;
    top: -30px;
    right: 10px;
    width: 80px;
    transform: translateX(0);
  }

  .recipe-quality img {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .our-recipe p {
    font-size: 5vw;
  }

  .recipe-component {
    margin: 30px 0;
  }

  .recipe-content {
    gap: 25px;
  }

  .recipe-content img {
    max-width: 100%;
  }

  .quality-content label,
  .quality-content span,
  .quality-content p,
  .quality-price-content p {
    font-size: 25px;
  }

  .quality-price {
    margin-left: 50px;
    gap: 20px;
  }

  .quality-price button {
    font-size: 22px;
    width: 80%;
    max-width: 200px;
  }

  .quality-price-content img {
    width: 12%;
    height: auto;
  }

  .recipe-quality {
    margin: 0;
    top: -50px;
    right: -20px;
    width: 150px;
  }

  .recipe-quality img {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 1024px) and (max-width: 1250px) {
  .recipe-quality {
    margin: 0 0%;
  }
}
